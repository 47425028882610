/**
 * Login Page
 */

// Core imports
import React from 'react';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, Grid, Row, styled, thd } from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';

import Logo from './images/logo-mirewards-reversed.svg';
import BgLogin from './images/bg-login.jpg';

import Footer from '../../components/CustomComponents/CustomFooter/Footer';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  background-color: ${thd('text', '#32343b')};
  background-image: url('${BgLogin}');
  background-size: cover;
  background-position: center center;
  display: flex;
  flex: 1 0 auto;
  height: 100%;
  min-height: 86vh;
  justify-content: center;
`;

const LoginContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  border: 1px solid #e3e3e3;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  max-width: 1100px;
  min-width: 300px;
  padding: 1rem;
  width: 100%;
  label {
    color: #fff;
  }
  .login__logo {
    height: 45px;
    width: 306px;
  }
  .login-form {
    .sui-button {
      margin-bottom: 1.6rem;
    }
  }
  .reset-password {
    a {
      color: #fff;
    }
  }
  .profile-form {
    .lori-input-help-text {
      color: #fff;
      a {
        color: ${thd('primary', 'red')};
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const LoginPage = () => (
  <PublicComponent redirectOnError="/">
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <LoginContainer>
        <Grid>
          <Row justifyContent="center">
            <Col xs={12} sm={10} md={8} lg={6}>
              <h1>
                <img className="login__logo" src={Logo} alt="MiRewards" />
              </h1>
              <h2 className="text--white text--tight">Welcome</h2>
              <p className="text--white">
                Please login below using the details already provided to you.
              </p>
              <LoginProvider disableMeta>
                <LoginForm
                  formLabels={{
                    labelUserName: 'Username (Your Mitsubishi SAP BP Number)',
                  }}
                />
              </LoginProvider>
            </Col>
          </Row>
        </Grid>
      </LoginContainer>
    </PageContainer>
    <Footer />
  </PublicComponent>
);

export default LoginPage;
